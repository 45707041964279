<template>
    <div class="bid_wrap curation_wrap">
        <div class="bid_main_wrap">
            <div class="bid_content_wrap">
                <div class="bid_title_wrap">
                    <h1 class="title">{{$t('setting_request')}}</h1>
                </div>

                <div class="content_wrap">
                    <div class="img_wrap">
                        <div class="b_img">
                            <img src="@/assets/image/main/curation_img.png"/>
                        </div>
                    </div>
                    <div class="inform_wrap">
                        <div class="inform_header">
                            <div class="header_title">
                                <h3 class="title_right">{{$t('input_subject')}}</h3>
                            </div>
                        </div>
                        <div class="inform_content">
                            <div class="content_top">
                                <div class="content_wrap1">
                                    <h3 class="sub_title curation_title">{{$t('mini_sell')}} 삭제예정</h3>
                                    <el-input class="amount" value="100,000"></el-input>
                                </div>
                                <div class="content_wrap2 second_input_wrap">
                                    <h3 class="sub_title curation_title">{{$t('a_curation')}} 삭제예정</h3>
                                    <el-input class="amount" value="10"></el-input>
                                </div>
                            </div>
                            <div class="c_caption">
                                <i>⁕</i><span>{{$t('curation_msg')}}</span>
                            </div>
                            <div class="search_item_wrap terms_content_btn">
                                <div class="search_item">
                                    <el-checkbox v-model="termsCheck" @change="changeCheck">
<!--                                        <span class="item_text_span bold" @click="openModal('terms-modal')">{{$t('terms_curation')}}</span>-->
<!--                                        <span class="item_text_span ">{{$t('agree_terms2')}}</span>-->
                                    </el-checkbox>
                                    <span class="item_text_span ml10" v-html="$t('apply_curation_text1')"></span>
                                </div>
                            </div>
                            <div class="btn_wrap">
                                <button class="cancel" @click="$router.back()">{{$t('cancel')}}</button>
                                <button class="gobid" @click="checkValidator()" >{{$t('request')}}</button>
                                <div class="validation" v-if="errMsg">{{errMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <terms-modal :termsType="`curation`"></terms-modal>-->
    </div>
</template>

<script>
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import TermsModal from "@/components/modal/TermsModal";
import curationValidator from "@/mixins/validators/curationValidator";
import AuthMixins from "@/mixins/AuthMixins";
export default {
    name: "CurationWriteDefaultLayout",
    mixins: [alertMixins, curationValidator,AuthMixins],
    components: {
        TermsModal
    },
    inject: ['curationWriteSetData'],
    provide() {
        return{

        }
    },
    props: {
        curationData: {default: () => {}},
        itemData: {default: () => {}},
    },
    data() {
        return{
            termsCheck: false,
            errMsg: '',
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setInitData();
        openTerms = this.openModal;
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setInitData() {
            this.curatorKey = Number(this.$route.params.mb_no);
            this.curationWriteSetData('curatorKey', this.curatorKey);
        },
        openModal(name) {
            this.$modal.show(name);
        },
        changeCheck() {
            this.errMsg = "";
        },
        requestConfirm() {
            this.createConfirm({
                content : this.$t('curation_c_msg1') +'<br/>'+ this.$t('curation_c_msg2') ,
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {
                    this.requestResult()
                },
                cancel: () => {

                }
            });
        },
        requestResult() {
            this.createConfirm({
                content : this.$t('curation_c_msg3') +'<br/>'+ this.$t('check_my') ,
                confirmText : this.$t('check_now'),
                cancelText : this.$t('check_later'),
                confirm: () => {
                },
                cancel: () => {

                }
            });
        },
        checkValidator() {
            if(!this.termsCheck) {
                this.errMsg = this.$t('curation_create_err2')
                return false;
            }
            if(!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            }else{
                this.requestConfirm();
            }


        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
